@import url('https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@500&display=swap');
.navbar {
    background-color: var(--main);
    padding: 10px 20px;
    opacity: 90%;
}

.header-top {
    margin-top: 0px;
    display: flex;
    justify-content: space-between;
}

.header-text {
    margin: auto;
    font-family: 'Black Han Sans', sans-serif;
    letter-spacing: 4px;
    vertical-align: middle;
    color: white;
    font-size: 60px;
    text-align: center;
}

.header-logo {
    width: 90px;
    vertical-align: middle;
    margin-right: 20px;
}

.header-spacer {
    opacity: 0;
    width: 160px; /*icon x number of icons*/ 
    height: 50px;
}

.header-icon-container {
    margin: auto 0;
}

.header-title {
    display:flex;
}

.header-icons {
    margin: 5px 3px;
    width: 40px;
    filter: brightness(0) invert(1);
    transition: 0.1s;
}

.header-icons:hover {
    filter: invert(69%) sepia(59%) saturate(6068%) hue-rotate(295deg) brightness(89%) contrast(89%);
    margin: 2px 0px;
    width: 46px;


}


@media (max-width: 768px) {
    .header-icons{
        display: none;
    }
}

.navbar-menu {
  list-style: none;
  display: flex;
  justify-content: space-around;
  margin-left: 30%;
  margin-right: 30%;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0;
}

/* @media (max-width: 768px) { */
/*     .navbar-menu{ */
/*         display: none; */
/*     } */
/* } */


.navbar-item {
    font-size: 20px;
}

.navbar-icons {
}

.navbar-link {
  color: white;
  text-decoration: none;
    font-family: 'Space Grotesk', sans-serif;
    font-size: 25px;
  padding: 5px 15px;
    transition: .3s;
    transition-timing-function: ease;
}

.navbar-link:hover {
  text-decoration: underline;
    color: var(--pink-text);
}

.hamburger-menu {
    display: none; /* Hidden by default, shown on mobile */
    cursor: pointer;
}

.hamburger-menu .bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    background-color: #fff;
}


/* Menu Items */
.navbar-menu {
    display: flex; /* Change to 'none' in mobile view */
    list-style: none;
}

/* Mobile view */
@media (max-width: 768px) {
    .hamburger-menu {
        display: block; /* Show hamburger icon on mobile */
    }

    .navbar-menu {
        display: none; /* Hide menu items initially on mobile */
        flex-direction: column; /* Stack items vertically */
    }

    .navbar-menu a {
        display: block;
        text-align: center;
        padding: 10px;
        border-top: 1px solid #eee;
    }
}
/* Show menu when active */
.navbar-menu.active {
    display: flex;
}
