/* Home.css */

.home-container {
}

.home-hero {
  text-align: center;
  background-size: 120%;
  background-position: 67%;
  padding: 1rem 20px;
    background-image:  linear-gradient( var(--main-bg), var(--main-bg) ), url("bg.png");
    padding-bottom: 30rem;
}


.home-hero h1 {
  font-size: 5rem;
    max-width: 40%;
  color: white;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 17rem;
}

@media (max-width: 768px) {
    .home-hero h1{
       text-align: center; 
        font-size: 4rem;
        max-width: 90%;
    }
    .home-hero {
     background-size: cover;
    }
}

.join-button {
  padding: 1.5rem 2.5rem;
  background-color: white; 
  color: var(--main);
  border: none;
  border-radius: 2.5rem;
  cursor: pointer;
  font-size: 3rem;
  transition: background-color 0.3s ease;
    font-weight: bold;
}

.join-button:hover {
  background-color: var(--pink-text); 
}

.home-about,
.home-members,
.home-what-we-do,
.home-sponsors {
  padding: 40px 20px;
  color: white;
}
/*to do later*/
.home-about {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent layer for readability */
}

.home-members {
  /* Style for members section */
}

.home-what-we-do {
  /* Style for what we do section */
}

.home-sponsors {
  /* Style for sponsors section */
}


