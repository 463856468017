.faq-page {
  background-image: url("https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.shutterstock.com%2Fsearch%2Fanime-wallpaper%3Fimage_type%3Dvector&psig=AOvVaw0itIL3K3qdqz4C7UMKlvu-&ust=1705764531665000&source=images&cd=vfe&ved=0CBMQjRxqFwoTCOjBspni6YMDFQAAAAAdAAAAABAE"); /* Replace*/
  background-size: cover;
  background-position: center;
  color: white;
  padding: 0px;
  border-radius: 8px;
}

.faq-header {
  font-size: 150px;
  text-align: center;
  margin-bottom: 70px;
  margin-top: 50px;
  font-weight: normal;
  color: var(--pink-text);
  font-family: "Train One", system-ui;
}

.faq-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  /* border-radius: 8px;  */
}

.faq-item {
  font-size: 30px;
  margin-bottom: 15px;
  border: 1px solid #ffffff50;
  /* border-radius: 8px;  */
  overflow: hidden;
}

.faq-question {
  padding: 15px;
  /* background: var(--pink-text);  */
  background: var(--pink-text);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s;
}

.faq-question:hover {
  background: var(--pink-text);
}

.faq-answer {
  border-top: 4px solid var(--main);
  padding: 15px;
  background: var(--pink-text);
  display: none;
}

.faq-item.open .faq-answer {
  display: block;
}

.faq-toggle {
  font-size: 24px;
}
