.contact-page {
  background-image: url("https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.shutterstock.com%2Fsearch%2Fanime-wallpaper%3Fimage_type%3Dvector&psig=AOvVaw0itIL3K3qdqz4C7UMKlvu-&ust=1705764531665000&source=images&cd=vfe&ved=0CBMQjRxqFwoTCOjBspni6YMDFQAAAAAdAAAAABAE");
  background-size: cover;
  background-position: center;
  color: white;
  padding: 0px;
  border-radius: 8px;
}
.contact-header {
  font-size: 150px;
  margin-top: 50px;
  text-align: center;
  margin-bottom: 20px;
  font-weight: normal;
  color: var(--pink-text);
  font-family: "Train One", system-ui;
}

.contact-tagline {
  font-size: 60px;
  text-align: center;
  margin-bottom: 20px;
  font-family: "PT Sans", sans-serif;
  font-weight: normal;
}

.contact-text {
  text-align: justify;
  font-size: 25px;
  margin-bottom: 20px;
  line-height: 1.5;
  padding: 60px 60px;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.email-button {
  font-weight: bold;
  margin: auto;
  background-color: var(--main);
  color: var(--pink-text);
  padding: 30px 50px;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  font-size: 50px;
  transition: background-color 0.3s ease;
  filter: drop-shadow(10px);
}

.email-button:hover {
  background-color: var(--pink-text);
  color: var(--main);
}

.flex {
  display: flex;
}

.margin-auto {
  margin: auto;
  display: block;
  margin-bottom: 400px;
}

@media (max-width: 768px) {
  .contact-header {
    font-size: 3.5rem;
    margin: 1rem;
  }
  .contact-tagline {
    font-size: 2.5rem;
  }
  .contact-text {
    padding: 30px 30px;
  }
  .margin-auto {
    margin-bottom: 40px;
  }
}
