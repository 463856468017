/*FONTS-------------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Train+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+New&display=swap");
/*------------------------------------------------------*/
:root {
  --body-font: "Zen Kaku Gothic New", sans-serif;
}
body {
  background-color: #2b012e;
}

.main-content {
  background-image: linear-gradient(var(--main-bg), var(--main-bg)),
    url("bg.png");
  background-size: 130%;
  background-position: 30%;
  background-repeat: repeat;
}

.content-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background-color: var(--main-container);
  backdrop-filter: blur(3px);
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: var(--pink-text) var(--main);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: var(--main);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--pink-text);
  border-radius: 10px;
  border: 3px solid var(--main);
}
