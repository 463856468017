.events-page {
  padding: 40px;
  background-color: #a55fa5;
  color: white;
  text-align: center;
}
.events-header {
  font-size: 10rem;
  text-align: center;
  margin-bottom: 70px;
  margin-top: 50px;
  font-weight: normal;
  color: var(--pink-text);
  font-family: "Train One", system-ui;
}

.events-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(450px, 400px));
  gap: 30px;
  justify-content: center;
  align-items: stretch;
  margin-bottom: 50px;
}

.event-card,
.event-image {
  border-radius: 15px;
  min-height: 300px;
  max-width: 500px;
  flex: 0 0 auto;
}

.event-image {
  height: 340px;
  width: 450px;
  background-position: center;
  background-size: cover;
}

/* .events-slider { */
/*     display: flex;  */
/*     overflow-x: auto;  */
/*     gap: 20px;  */
/*     padding: 20px;  */
/*     scrollbar-width: thin;  */
/*     scrollbar-color: #d1d1d1 #444;  */
/* } */
/**/
/* /* For Webkit browsers */
/* .events-slider::-webkit-scrollbar { */
/*     height: 10px; */
/* } */
/**/
/* .events-slider::-webkit-scrollbar-thumb { */
/*     background: #d1d1d1; */
/*     border-radius: 10px; */
/* } */
/**/
/* .events-slider::-webkit-scrollbar-track { */
/*     background: #444; */
/* } */
/**/
.event-image {
  min-height: 300px;
}

.event-card {
  color: var(--main);
  background: var(--main-bg);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: left;
  opacity: 0;
  transition: opacity 0.2s;
  transition-timing-function: ease;
}

.event-card:hover {
  opacity: 1;
  cursor: pointer;
}

.event-name {
  font-weight: bold;
  font-size: 50px;
  margin-bottom: 15px;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.event-description {
  font-size: 25px;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

@media (max-width: 768px) {
  .events-header {
    font-size: 4rem;
    margin: 1rem;
  }
  .event-card,
  .event-image {
    border-radius: 15px;
    height: 150px;
    max-width: 500px;
    flex: 0 0 auto;
  }
  .event-image {
    height: 340px;
    width: 340px;
    background-position: center;
    background-size: cover;
  }

  .event-name {
    font-size: 35px;
  }
  .event-description {
    font-size: 20px;
  }
  .events-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(5.2rem, 400px));
    gap: 30px;
    justify-content: center;
    align-items: stretch;
    margin-bottom: 50px;
  }
}
