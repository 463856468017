.footer {
    background-color: var(--bottom); 
    color: var(--pink-text);
    align-items: center;
}

.footer-top {
    display: flex;
    justify-content: space-evenly;
    padding-top: 20px;
}

.footer-info,
.footer-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-info {
    align-items: flex-start;
}

.footer-logo {
    margin-bottom: 10px;
}

.footer-text-bottom {
    text-align: center;
    padding: 10px;
}

.footer-top-item {
    margin: auto;
}

.footer-info-text {
    font-size: 17px;
    max-width: 200px;
    padding: 2px;
    margin: 0;
}

.contact-button {
    font-family: 'Black Han Sans', sans-serif;
    font-size: 20px;
    background-color: var(--main); 
    border: none;
    padding: 10px 25px;
    color: var(--pink-text);
    cursor: pointer;
    margin: auto;
    margin-top: 5px;
  transition: background-color 0.3s ease;
}

.contact-button:hover {
    background-color: var(--pink-text);
    color: var(--main);
}

.bottom-text, bottom-logo {
    margin: auto;
}

.bottom-text {
    font-family: 'Black Han Sans', sans-serif;
    font-size: 25px;
}

.footer-icons {
    margin: 5px 3px;
    width: 40px;
    filter: invert(69%) sepia(59%) saturate(6068%) hue-rotate(295deg) brightness(89%) contrast(89%);
    transition: 0.1s;
}


.footer-icons:hover {
    margin: 2px 0px;
    width: 46px;
}


.bottom-container {
    padding: 10px;
    display: flex;
    justify-content: center;
    background-color: var(--main);
    margin: 0;
}

.bottom-container-item {
    display: flex;
    margin: 0px 40px;
}

.bottom-logo {
    width: 30px;
    height: 30px;
    padding: 0 10px;
    
    filter: brightness(0) invert(69%) sepia(59%) saturate(6068%) hue-rotate(295deg) brightness(89%) contrast(89%);
} 

.footer-bottom {
    list-style: none;
    display: flex;
    justify-content: space-around;
    margin-left: 35%;
    margin-right: 35%;
}

@media (max-width: 768px) {
    .footer-top {
        display: none;
    }
}
