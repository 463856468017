/* Sponsors.css */

.sponsors-container {
  /* text-align: center; */
  /* padding: 40px; */
  /* background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  /* backdrop-filter: blur(5px); /* Creates a blurred background */
  /* color: white; */
}

.sponsors-header {
  font-size: 10rem;
  text-align: center;
  margin-bottom: 5rem;
  margin-top: 4rem;
  font-weight: normal;
  color: var(--pink-text);
  font-family: "Train One", system-ui;
}

.sponsors-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.sponsor-card {
  position: relative;
  height: 400px;
  width: 400px;
  display: flex;
  background-color: var(--main-bg);
  border-radius: 30px;
}

.sponsor-details {
  position: absolute;
  border-radius: 30px;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: opacity 0.2s;
  background-color: #eeeeee;
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  text-align: center;
}

.sponsor-details:hover {
  opacity: 0.8;
}

.sponsor-name {
  font-size: 50px;
  font-family: "Zen Kaku Gothic New", sans-serif;
  color: var(--main);
  margin: auto;
  max-width: 80%;
}

.sponsor-description {
  font-size: 25px;
  font-family: "Zen Kaku Gothic New", sans-serif;
  color: var(--main);
  margin: auto;
  max-width: 80%;
}

.sponsor-logo {
  max-height: 80%;
  max-width: 80%;
  margin: auto;
}

@media (max-width: 768px) {
  .sponsors-header {
    font-size: 3rem;
    margin: 1rem;
  }
  .sponsor-card {
    height: 300px;
    width: 300px;
  }
}
